import React from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@reach/dialog'
import { Phone, Check } from 'react-feather'
import tw, { css, styled } from 'twin.macro'

const Modal = styled(Dialog)`
	${tw`bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6`}
`

export default function PopUp({ showDialog, close }) {
	return (
		<Modal data-no-focus-lock isOpen={showDialog} onDismiss={close}>
			<div>
				<div tw="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
					<Check tw="h-6 w-6 text-green-600" />
				</div>
				<div tw="mt-3 text-center sm:mt-5">
					<h3
						tw="text-lg leading-6 font-medium text-gray-900"
						id="modal-headline"
					>
						Form Sent
					</h3>
					<div tw="mt-2">
						<p tw="text-sm leading-5 text-gray-500">
							We have recieved your information and our customer service team
							will be in contact with you shortly. If you have any other
							questions or require immediate assistance please call us on
							<a
								href="tel:1300693657"
								tw="inline-flex items-center justify-center py-2 text-sm leading-5 text-gray-700 font-medium hover:text-gray-500 transition ease-in-out duration-150"
							>
								<Phone tw="w-5 h-5 text-gray-400" />
								<span tw="ml-3">1300 693 657</span>
							</a>
						</p>
					</div>
				</div>
			</div>
			<div tw="mt-5 sm:mt-6">
				<span tw="flex w-full rounded-md shadow-sm">
					<button
						onClick={close}
						type="button"
						tw="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-400 focus:outline-none focus:border-blue-200 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
					>
						Close
					</button>
				</span>
			</div>
		</Modal>
	)
}
PopUp.propTypes = {
	showDialog: PropTypes.bool,
	close: PropTypes.func,
}
